var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg",attrs:{"color":"surface"}},[_c('section',{staticClass:"pa-6"},[_c('h1',{staticClass:"text-h5"},[_vm._v("Meus agendamentos")])]),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.schedules,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.handleStatus(item.status).color,"dark":"","small":""}},[_vm._v(" "+_vm._s(_vm.handleStatus(item.status).label)+" ")])]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" R$ "+_vm._s(_vm.moneyMask(item.value))+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.qtd",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.schedules.length)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"rounded-lg",attrs:{"icon":""},on:{"click":function($event){return _vm.redirect(item._id)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)}),_c('v-row',{staticClass:"pa-4",attrs:{"justify":"center"}},[_c('v-pagination',{attrs:{"color":"primary","length":_vm.pagination.pageCount,"total-visible":10,"circle":""},on:{"input":function($event){return _vm.getData()}},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card color="surface" class="rounded-lg">
    <section class="pa-6">
      <h1 class="text-h5">Meus agendamentos</h1>
    </section>

    <v-divider />

    <v-data-table
      :headers="headers"
      :items="schedules"
      disable-pagination
      hide-default-footer
    >
      <!-- status -->
      <template v-slot:[`item.status`]="{ item }">
        <v-chip :color="handleStatus(item.status).color" dark small>
          {{ handleStatus(item.status).label }}
        </v-chip>
      </template>

      <!-- value -->
      <template v-slot:[`item.value`]="{ item }">
        R$ {{ moneyMask(item.value) }}
      </template>

      <!-- date -->
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>

      <!-- qtd -->
      <template v-slot:[`item.qtd`]="{ item }">
        {{ item.schedules.length }}
      </template>

      <!-- actions -->
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn class="rounded-lg" icon @click="redirect(item._id)">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-row class="pa-4" justify="center">
      <!-- pagination -->
      <v-pagination
        v-model="pagination.page"
        color="primary"
        :length="pagination.pageCount"
        :total-visible="10"
        circle
        @input="getData()"
      />
    </v-row>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import { getSchedules } from "@/services/schedules";
import { moneyMask, formatDate } from "@/utils";

export default {
  data() {
    return {
      loading: true,
      headers: [
        { text: "Status", value: "status", align: "center" },
        { text: "Valor", value: "value", align: "center" },
        { text: "Data", value: "createdAt", align: "center" },
        { text: "Quantidade de items", value: "qtd", align: "center" },
        { text: "Ações", value: "actions", align: "center" },
      ],
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
      },
      schedules: [],
    };
  },

  computed: {
    ...mapState(["user"]),
  },

  beforeMount() {
    this.getData();
  },

  methods: {
    async getData() {
      try {
        const payload = {
          page: this.pagination.page - 1,
          size: this.pagination.itemsPerPage,
        };

        await getSchedules(payload).then((res) => {
          this.schedules = res.content;
          this.pagination.pageCount = res.totalPages;
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    redirect(id) {
      this.$router.push({ path: `/schedules/new?redirect=${id}` });
    },

    handleStatus(value) {
      switch (value) {
        case "AWAITING":
          return {
            label: "Aguardando",
            color: "amber",
          };
        case "PAID":
          return {
            label: "Aprovado",
            color: "green",
          };
        case "CANCELED":
          return {
            label: "Cancelado",
            color: "red",
          };
        case "TIMEOUT":
          return {
            label: "Expirado",
            color: "red",
          };
        default:
          return {
            label: "Carregando",
            color: "subtext",
          };
      }
    },

    moneyMask,

    formatDate,
  },
};
</script>

<style>
</style>
import { request } from "./index";

export const createScheduling = (data) => request('post', '/scheduling', data)

export const getItems = (data) => request("get", `/scheduling/item?type.name=${data}`);

export const getCalendar = (data) => request('get', `/scheduling/calendar?item=${data}`)

export const getHours = (data) => request('get', `/scheduling/available/hours?item=${data.item}&date=${data.date}`)

export const getAvailable = (data) => request("get", `/scheduling/available?item=${data.item}&date=${data.date}`);

export const getSchedules = (data) => request("get", `/scheduling?page=${data.page}&size=${data.size}&sort=createdAt,desc&_id=${data._id}`);

